// Footerrrrrrrr _**********************************************************

footer {
  padding: 60px 0;
  background-size: cover;
  background-color: #101828;

  p {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
    color: #abbdd5;
    opacity: 1;
  }

  h4 {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    font-family: "Gilroy", sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  .link_ul {
    margin-top: 45px;
    padding: 0;
    list-style: none;
    margin-bottom: 0;

    li {
      padding-bottom: 24px;
      text-transform: capitalize;

      &:last-child {
        padding-bottom: 0;
      }
    }

    a {
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 32px;
      font-family: "Montserrat", sans-serif;
      letter-spacing: 0px;
      color: #abbdd5;
      opacity: 1;
      transition: 0.6s;
      text-decoration: none;
    }

    a:hover {
      color: orangered;
      letter-spacing: 1px;
    }

  }
}

.footer-logo-img {
  width: 180px;
  margin-bottom: 25px;
}

.social_ul {
  margin-top: 30px;
  display: flex;

  &_img {
    width: 35px;
    filter: grayscale(0%);
  }

  li {
    padding-right: 20px;
  }

  a {
    transition: 0.6s;

    &:hover img {
      transform: rotate(360deg);
      transition: 0.6s;
      filter: grayscale(100%);
    }
  }
}

