@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gilroy&display=swap");

// font-family: "Montserrat", sans-serif;
// font-family: 'Gilroy', sans-serif;


/* Universal Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Remove list styles */
ul{
  padding-left: 0px !important;
}
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

/* Remove text decoration */
a {
  text-decoration: none;
  color: inherit;
}

/* Additional styles */
body {
  font-family: Arial, sans-serif; /* Default font family */
  line-height: 1.6; /* Default line height */
}

header{
    background-color: #efebeb;
    position: inherit;
    width: 100%;
}
.navbar {
  background-color: transparent; 
  transition: background-color 0.3s ease;
  padding: 20px 0px !important;

  .logo{
    width: 150px;
  }

  .nav-link {
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
    color: #000;
    border: 1px solid transparent;
    transition: 1s;
    letter-spacing: .1px;

    &:hover {
      color: orangered;
      border-bottom: 1px solid orangered;
    }
    &:last-child{
      margin-right: 0px;
    }
  }

  .nav-btn{
    color: #000;
    border: 1px solid #000;
    margin-right: 20px;
    padding: 7px 25px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    text-decoration: none;

    &:last-child{
      margin-right: 0;
    }

    &:hover{
      color: #fff;
      border: 1px solid orangered;
      background: orangered;
      transition: .5s;
    }
  }

}
.navbar.scrolled {
  background-color: white; 
}

// Coman Css --------------------------

.section-heading{
  font-size: 42px;
  // line-height: 35px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 20px;
  background: linear-gradient(to right, red , blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-peragrph{
  font-size: 18px;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #000;
  margin-bottom: 10px;
}

.heading-style {
  color: orangered;
}