.network-section{
    padding: 60px 0;
    background-color: aliceblue;

    .title-div{
        margin-bottom: 50px;
    }
    
    ul{
        padding-left: 0;
        margin-bottom: 30px;
    }

    li{
        font-size: 20px;
        line-height: 30px;
        font-family: 'Gilroy', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: #000;
        text-transform: capitalize;
        margin-bottom: 25px;

        &:nth-child(2){
            margin-left: 60px;
        }

        &:last-child{
            margin-bottom: 0;
        }

        img{
            margin-right: 15px;
        }
    }
}

