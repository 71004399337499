.service-section {
  padding: 60px 0;
}

.Distributor_section {
  background-color: antiquewhite;
  padding: 60px 0;

  h2 {
    color: orangered;
    font-size: 42px;
    font-weight: 700;
  }

  h3 {
    color: orangered;
    font-size: 30px;
    font-family: "Gilroy", sans-serif;
    font-weight: 600;
    margin-top: 20px;
  }
  h4 {
    color: orangered;
    font-size: 23px;
    font-family: "Gilroy", sans-serif;
    font-weight: 600;
  }

  .register-btn {
    color: #fff;
    font-size: 18px;
    font-family: "Gilroy", sans-serif;
    background-color: orangered;
    border: 1px solid orangered;
    text-decoration: none;
    padding: 10px 54px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 7px;
    margin-top: 20px;
    display: inline-block;

    &:hover {
      border: 1px solid orangered;
      color: orangered;
      background-color: unset;
      transition: 1s;
    }
  }

  .Distributor_second_row {
    margin-top: 80px;
  }
}

// ------------ Carousel Css
.carousel-main-div {
  margin-top: 30px;
}

.slider-card {
  border: 1px solid orangered;
  padding: 10px;
  border-radius: 30px 0px;
  min-height: 238px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    border: 1px solid orangered;
    background-color: orangered;
    position: relative;
    z-index: 1;
    display: flex;
    padding: 12px;
    border-radius: 50px;
    width: 75px;
    height: 75px;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
  }
  .icons-img {
    width: 35px !important;
  }
  h3 {
    margin: 20px 0;
    color: orangered;
    font-size: 18px;
    font-family: "Gilroy", sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 1px;
  }
  p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
    text-align: center;
  }
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  position: absolute;
  top: 50%; /* Position at 50% from the top of the carousel container */
  transform: translateY(-50%); /* Center vertically using transform */
  font-size: 30px;
}

.owl-carousel .owl-nav button.owl-prev {
  left: -70px; /* Position the left arrow button on the left side of the carousel */
  // font-size: 50px;

  span {
    font-size: 50px !important;
    // background: orangered !important;
    color: #000;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
  &:hover {
    background: unset;
  }
}

.owl-carousel .owl-nav button.owl-next {
  right: -70px; /* Position the right arrow button on the right side of the carousel */

  span {
    font-size: 50px !important;
    // background: orangered !important;
    color: #000;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }

  &:hover {
    background: unset;
  }
}
.owl-theme .owl-nav .disabled {
  opacity: inherit !important;
  cursor: default;
}

// .owl-theme .owl-nav [class*='owl-']:hover {
//   background: inherit;
//   color: #FFF;
//   text-decoration: none;
// }
