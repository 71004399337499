.Contact_section {
  padding: 80px 0px;
  h2 {
    font-size: 40px;
    text-align: center;
    color: orangered;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .Form_Div {
    border: 1px solid gainsboro;
    padding: 30px;
    border-radius: 40px;

    .form-title-div {
      margin-bottom: 25px;
    }
    
  }

  .form-inputs-div {
    input, textarea {
      padding: 15px;
      margin-bottom: 20px;
      border: .5px solid rgb(211, 210, 210);
      border-radius: 5px;
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .register-btn {
      color: #fff;
      padding: 15px;
      border-radius: 20px;
      background-color: orangered;
      font-weight: 600;
      font-size: 18px;
      text-transform: uppercase;
      transition: 1s;

      &:hover {
        color: orangered;
        border: 1px solid orangered;
        background-color: unset;
      }
    }
  }
}

.girl-comunity {
  width: 450px;
  height: 400px;
  object-fit: cover;
  transition: transform 0.5s ease; /* Smooth transition for hover effects */
  animation: upDown 2s infinite ease-in-out; /* Apply up-down animation */
}

/* Define the up-down keyframes */
@keyframes upDown {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-20px); /* Move up by 20px */
  }
}

/* Optional: Add hover effects if desired */
.girl-comunity:hover {
  transform: rotate(360deg) scale(1.2);
}