@media (min-width: 1024px) {
  .navbar .nav-link {
    font-size: 14px;
    margin-right: unset;
  }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    right: -40px;
  }
 
}
/* Desktop styles */
@media (min-width: 768px) and (max-width: 1024px) {
  .Banner_section {
    padding: 25px 0px;
  }
  .Banner_section h1 {
    font-size: 28px;
    line-height: 36px;
    text-align: left;
  }
  .Banner_section p {
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    margin-top: 0px;
  }

  .network-section {
    padding: 25px 0;
  }
  .section-heading {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 0px;
  }
  .section-peragrph {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-top: 10px;
  }
  .network-section .title-div {
    margin-bottom: 15px;
  }
  .network-section ul {
    margin-bottom: 15px;
    text-align: left;
  }
  .network-section li {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .network-section li:nth-child(2) {
    margin-left: 0px;
  }
  .network-section li img {
    margin-right: 15px;
    width: 35px;
  }
  .network-section .network-paras {
    text-align: left;
  }

  .service-section {
    padding: 25px 0;
  }
  .slider-card {
    min-height: 200px;
    width: 100%;
    margin: auto;
  }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    display: none;
  }
  .section-peragrph-mob {
    text-align: left;
  }

  .Distributor_section {
    padding: 25px 0;
  }
  .Distributor_section h2 {
    font-size: 28px;
    text-align: center;
    margin-top: 10px;
  }
  .Distributor_section h3 {
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
  }
  .why-choose-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Distributor_section .Distributor_second_row {
    margin-top: 22px;
  }
  .Distributor_section h4 {
    text-align: center;
  }
  .Distributor_section .distributer-img-mob {
    margin-top: 25px;
  }

  .Contact_section {
    padding: 25px 0px;
  }

  .Contact_section .Form_Div {
    padding: 20px;
    width: 100%;
    margin: 0 auto 20px;
  }
  .Contact_section .Form_Div h2 {
    font-size: 28px;
  }
  .Contact_section .form-inputs-div input,
  .Contact_section .form-inputs-div textarea {
    padding: 10px;
  }
  .Contact_section .form-inputs-div .register-btn {
    padding: 10px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 15px;
  }

  .navbar .nav-link {
    font-size: 14px;
    margin-right: unset;
  }
}

/* Tablet styles */
@media (min-width: 480px) and (max-width: 768px) {
  .Banner_section {
    padding: 25px 0px;
  }
  .Banner_section h1 {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  }
  .Banner_section p {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-top: 0px;
  }

  .network-section {
    padding: 25px 0;
  }
  .section-heading {
    font-size: 28px;
    /* line-height: 28px; */
    margin-bottom: 0px;
  }
  .section-peragrph {
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    margin-top: 10px;
  }
  .network-section .title-div {
    margin-bottom: 15px;
  }
  .network-section ul {
    margin-bottom: 15px;
    text-align: center;
  }
  .network-section li {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .network-section li:nth-child(2) {
    margin-left: 0px;
  }
  .network-section li img {
    margin-right: 15px;
    width: 35px;
  }
  .network-section .network-paras {
    text-align: center;
  }

  .service-section {
    padding: 25px 0;
  }
  .slider-card {
    min-height: 200px;
    width: 90%;
    margin: auto;
  }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    display: none;
  }
  .section-peragrph-mob {
    text-align: left;
  }

  .Distributor_section {
    padding: 25px 0;
  }
  .Distributor_section h2 {
    font-size: 28px;
    text-align: center;
    margin-top: 10px;
  }
  .Distributor_section h3 {
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
  }
  .why-choose-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Distributor_section .Distributor_second_row {
    margin-top: 22px;
  }
  .Distributor_section h4 {
    text-align: center;
  }
  .Distributor_section .distributer-img-mob {
    margin-top: 25px;
  }

  .Contact_section {
    padding: 25px 0px;
  }

  .Contact_section .Form_Div {
    padding: 20px;
    width: 100%;
    margin: 0 auto 20px;
  }
  .Contact_section .Form_Div h2 {
    font-size: 28px;
  }
  .Contact_section .form-inputs-div input,
  .Contact_section .form-inputs-div textarea {
    padding: 10px;
  }
  .Contact_section .form-inputs-div .register-btn {
    padding: 10px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 15px;
  }
}

/* Styles for mobile phones (screen width 480px and below) */
@media (max-width: 480px) {
  .Banner_section {
    padding: 25px 0px;
  }
  .Banner_section h1 {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
  }
  .Banner_section p {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin-top: 0px;
  }

  .network-section {
    padding: 25px 0;
  }
  .section-heading {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 0px;
  }
  .section-peragrph {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin-top: 10px;
  }
  .network-section .title-div {
    margin-bottom: 15px;
  }
  .network-section ul {
    margin-bottom: 15px;
    text-align: center;
  }
  .network-section li {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .network-section li:nth-child(2) {
    margin-left: 0px;
  }
  .network-section li img {
    margin-right: 15px;
    width: 35px;
  }
  .network-section .network-paras {
    text-align: center;
  }

  .service-section {
    padding: 25px 0;
  }
  .slider-card {
    min-height: 200px;
    width: 90%;
    margin: auto;
  }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next {
    display: none;
  }
  .section-peragrph-mob {
    text-align: left;
  }

  .Distributor_section {
    padding: 25px 0;
  }
  .Distributor_section h2 {
    font-size: 22px;
    text-align: center;
    margin-top: 10px;
  }
  .Distributor_section h3 {
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
  }
  .why-choose-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Distributor_section .Distributor_second_row {
    margin-top: 22px;
  }
  .Distributor_section h4 {
    text-align: center;
  }
  .Distributor_section .distributer-img-mob {
    margin-top: 25px;
  }

  .Contact_section {
    padding: 25px 0px;
  }

  .Contact_section .Form_Div {
    padding: 20px;
    width: 90%;
    margin: 0 auto 20px;
  }
  .Contact_section .Form_Div h2 {
    font-size: 25px;
  }
  .Contact_section .form-inputs-div input,
  .Contact_section .form-inputs-div textarea {
    padding: 10px;
  }
  .Contact_section .form-inputs-div .register-btn {
    padding: 10px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 15px;
  }
}
