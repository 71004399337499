.WhyChoose-section {
  padding: 60px 0;
  background-color: ghostwhite;

  &-card-row {
    margin-top: 30px !important;
  }
}

.choose-card {
  border: 1px solid rgb(236, 236, 236);
  border-top: 6px solid greenyellow;
  padding: 15px 0px;
  text-align: center;
  border-radius: 10px;
  transition: 1s;
  min-height: 468px;
  margin-bottom: 20px;
  
  &:hover {
    border: 1px solid greenyellow;
    border-top: 6px solid greenyellow;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  }

  img {
    max-height: 230px;
    object-fit: cover;
    transition: transform 0.7s ease-in-out;
  }

  h3 {
    color: orangered;
    font-size: 18px;
    font-family: "Gilroy", sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 1px;
  }

  p {
    font-size: 12px;
    line-height: 22px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #000;
    margin-bottom: 0px;
    padding: 10px;
  }

  &:hover img {
    transform: rotate(360deg) scale(1); /* Rotate and zoom */
  }
}
