.Banner_section {
  padding: 80px 0px 70px;

  // &-row {
  //   padding-top: 175px;
  // }

  .comunity-name {
    width: 200px;
  }

  h1 {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 42px;
    line-height: 55px;
    font-family: "Gilroy", sans-serif;
    letter-spacing: 0px;
    color: #1d2939;
    opacity: 1;
    font-weight: bold;
  }
  .heading-style {
    color: orangered;
  }

  p {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 34px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
    color: #1D2939;
    opacity: 1;
    margin-top: 20px;
    margin-top: 20px;
  }

}
